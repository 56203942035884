<template>
  <div>
    <b-card>
      <search-card title="Hızlı Araç Sorgulama" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import SearchCard from '@/views/Expertise/Components/SearchCard.vue'

export default {
  name: 'DashboardIndex',
  components: {
    SearchCard,
    BCard,
  },
}
</script>
